<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="24" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form ref="addOutboundForm" :model="amazonShipmentForm" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundOrderNo')" prop="outboundCode">{{ amazonShipmentForm.outboundCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundType')" prop="outboundCode">{{ getOutboundTypeLabel(amazonShipmentForm.outboundType) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundWH')" prop="outboundCode">{{ amazonShipmentForm.warehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundSite')" prop="outboundCode">{{ amazonShipmentForm.siteCode }}</el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item :label="$t('page.outboundBoxNum')" prop="outboundCode">{{ amazonShipmentForm.quantity }}</el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item :label="$t('page.outboundNum')" prop="outboundCode">{{ amazonShipmentForm.pairs }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="amazonShipmentForm.remark" type="textarea" :placeholder="$t('page.inputPlaceholder')" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="6" class="bg-purple-title">{{ $t('page.ShipmentDetails') }}</el-col>
      <el-col v-if="amazonShipmentForm.platformCode === 'amazon' " :span="10">
        <span>指定刷港口</span>
        <el-select
          v-model="harbor"
          style="margin:0 10px"
          :placeholder="$t('page.selectPlaceholder')"
        >
          <el-option
            v-for="item in shipmentPlanOptions"
            :key="item"
            :value="item"
            :label="item"
          />
          <!-- <el-option label="ABE3" value="ABE3" />
          <el-option label="MDT2" value="MDT2" />
          <el-option label="IND4" value="IND4" />
          <el-option label="PHX3" value="PHX3" /> -->
        </el-select>
        <el-button :disabled="startHarbor" type="primary" @click="startAutoCreateShipmentPlan">{{ messageText }}</el-button>
        <el-button :loading="stopHarborLoading" @click="stopAutoCreateShipmentPlan">结束</el-button>
      </el-col>
      <el-col :span="8" class="float-right">
        <el-button type="primary" :disabled="disabledAmazonShipment" @click="createAmazonShipmentPlan">{{ $t('page.createShipmentPlan') }}</el-button>
        <el-button type="primary" :disabled="disabledAmazonShipment" @click="createAmazonShipment">{{ $t('page.createShipment') }}</el-button>
      </el-col>
    </el-row>
    <el-table ref="shipmentPlanTableRef" v-loading="shipmentTableLoading" :default-sort="{prop: 'createTime', order: 'descending'}" class="mb-3" :data="shipmentPlanTable" :header-cell-style="{background:'#fafafa'}">
      <el-table-column label="选择" width="80" center>
        <template slot-scope="scope">
          <el-radio
            v-model="selectRadio"
            name="shipmentRadio"
            :label="scope.$index"
            class="radio"
            :disabled="Boolean(scope.row.isUse)"
            @change.native="getCurrentRow(scope.row ,scope.$index)"
          >&nbsp;</el-radio>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="shipmentId"
        label="shipment ID"
        align="center"
      />
      <el-table-column
        prop="shipmentName"
        label="shipment Name"
        align="center"
      />
      <el-table-column
        prop="destinationFulfillmentCenterId"
        :label="$t('page.ShippingPort')"
        width="120"
        align="center"
      />
      <el-table-column
        sortable
        prop="createTime"
        :label="$t('page.createTime')"
        width="100"
        align="center"
      />
      <el-table-column
        prop="isUse"
        :label="$t('page.isUse')"
        width="120"
        align="center"
      >
        <template slot-scope="scope">{{ scope.row.isUse ? $t('title.Yes') : $t('title.No') }}</template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="出库明细信息"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
    >
      <el-table ref="shipmentPlanTableRefs" :default-sort="{prop: 'createTime', order: 'descending'}" class="mb-3" :data="amazonDetail" :header-cell-style="{background:'#fafafa'}">
        <el-table-column
          :label="$t('page.No')"
          type="index"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="shipmentId"
          label="shipmentId"
          align="center"
        />
        <el-table-column
          prop="shipmentName"
          label="sku"
          align="center"
        />
        <el-table-column
          prop="destinationFulfillmentCenterId"
          label="style"
          width="120"
          align="center"
        />
        <el-table-column
          sortable
          prop="createTime"
          label="fnsku"
          width="100"
          align="center"
        />
        <el-table-column
          sortable
          prop="actualPairs"
          label="实际出库数量"
          width="100"
          align="center"
        />
      </el-table>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </el-dialog>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>
import { Mixin } from '@/mixin/mixin.js'
import { getCenterId, startAutoCreateShipmentPlan, stopAutoCreateShipmentPlan, addShipment,
  findOutbound, createShipmentPlan, findShipmentPlan, createShipmentPlanTool,
  findOutboundAmazonDetail, addAmazonShipmentIdDetail } from '@/api/stock-out'
import { Loading } from 'element-ui'
export default {
  mixins: [Mixin],
  data() {
    return {
      disabledAmazonShipment: false,
      startHarbor: false,
      shipmentTableLoading: false,
      stopHarborLoading: false,
      startHarborLoading: false,
      amazonShipmentForm: {
        outboundCode: '',
        sendSiteCode: '',
        warehouseName: '',
        outboundType: '',
        quantity: '',
        pairs: '',
        remark: ''
      },
      selectRadio: false,
      shipmentPlanSelection: {},
      shipmentPlanTable: [],
      harbor: '',
      messageText: '开始',
      shipmentPlanOptions: [],
      amazonDetail: [],
      dialogVisible: false
    }
  },
  computed: {
    startAutoCreateShipmentPlanParams() {
      const { siteCode, shopCode } = this.$route.query
      const { outboundCode } = this.amazonShipmentForm
      return Object.assign({}, { outboundCode, siteCode, shopCode }, { destinationFulfillmentCenterId: this.harbor })
    }
  },
  mounted() {
    this.getQueryData()
    this._getCenterId()

    const { row } = this.$route.query
    this.amazonShipmentForm = row && JSON.parse(row) || {}
    console.log('%c 🥗  this.DetailsDatas: ', 'font-size:20px;background-color: #4b4b4b;color:#fff;', this.DetailsDatas)
    if (!Object.keys(this.amazonShipmentForm).length) {
      return
    }
  },
  methods: {
    async _getCenterId(siteCode) {
      const { datas } = await getCenterId(this.$route.query.siteCode)
      this.shipmentPlanOptions = datas
    },
    // 开始->根据港口自动创建shipment计划
    async startAutoCreateShipmentPlan(data) {
      if (!this.harbor) {
        this.$message({
          message: '指定刷港口不能为空',
          type: 'warning'
        })
      } else {
        this.startHarbor = true
        this.disabledAmazonShipment = true
        this.messageText = '执行中'

        try {
          const { msg } = await startAutoCreateShipmentPlan(this.startAutoCreateShipmentPlanParams)
          this.$notify({
            title: '操作成功',
            message: msg,
            type: 'success'
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    // 停止->根据港口自动创建shipment计划
    async stopAutoCreateShipmentPlan(data) {
      this.messageText = '开始'

      this.startHarbor = false
      this.disabledAmazonShipment = false
      this.stopHarborLoading = true
      try {
        const { msg, code } = await stopAutoCreateShipmentPlan(this.amazonShipmentForm.outboundCode)
        this.$notify({
          title: '操作成功',
          message: msg,
          type: 'success'
        })
        if (code === 0) {
          this.getQueryData()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.stopHarborLoading = false
      }
    },
    getQueryData() {
      const { outboundCode } = this.$route.query
      // this.amazonShipmentForm.outboundCode = outboundCode
      // this._findOutbound(outboundCode)
      this._findShipmentPlan(outboundCode)
    },
    getCurrentRow(row, index) {
      this.shipmentPlanSelection = row
    },
    createAmazonShipmentPlan() {
      try {
        const { outboundCode } = this.amazonShipmentForm
        this._createShipmentPlan(outboundCode)
      } catch (error) {
        this.shipmentTableLoading = false
      }
    },
    createAmazonShipment() {
      this._createShipmentByPlan()
    },
    cancle() {
      this.$router.go(-1)
    },
    async _findShipmentPlan(outboundCode) {
      const { datas } = await findShipmentPlan(outboundCode)
      this.shipmentPlanTable = datas
    },
    async _findOutbound(outboundCode) {
      const { datas } = await findOutbound(outboundCode)
      this.amazonShipmentForm = datas
      if (datas.createShipmentStatus === 1) {
        this.startHarbor = true
        this.messageText = '执行中'
      } else {
        this.startHarbor = false
        this.messageText = '开始'
      }
    },
    async _createShipmentPlan(outboundCode) {
      this.shipmentTableLoading = true
      const { datas } = await createShipmentPlan(outboundCode)
      if (datas.id) {
        this.shipmentPlanTable.push(datas)
        this.shipmentTableLoading = false
        const {
          destinationFulfillmentCenterId,
          outboundCode,
          platSku,
          platformCode,
          shopCode,
          siteCode,
          style,
          targetType
        } = datas
        this._createShipmentPlanTool({
          destinationFulfillmentCenterId,
          outboundCode,
          platSku,
          platformCode,
          shopCode,
          siteCode,
          style,
          targetType
        })
      }
    },
    async _createShipmentPlanTool(params) {
      const { datas } = await createShipmentPlanTool(params)
      if (datas.length) {
        this.shipmentPlanTable = datas
      }
    },
    async _findOutboundAmazonDetail(params) {
      const { datas: { item }} = await findOutboundAmazonDetail(params)
      if (item.length) {
        this.amazonDetail = item
      }
    },
    async submit() {
      const { shipmentId, shopCode, siteCode, platformCode } = this.shipmentPlanSelection
      const { tableSuffix, outboundCode } = this.amazonShipmentForm
      const params = {
        operationType: 'ADD',
        outboundCode,
        platformCode,
        shipmentId,
        shopCode,
        siteCode,
        tableSuffix
      }
      const { msg } = await addAmazonShipmentIdDetail(params)
      if (msg) {
        this.$notify({
          title: '操作成功',
          message: msg,
          type: 'success'
        })
        this.dialogVisible = false
      }
    },
    async _createShipmentByPlan() {
      const { shipmentId, shopCode, siteCode, platformCode } = this.shipmentPlanSelection
      const { tableSuffix, outboundCode } = this.amazonShipmentForm
      const params = {
        outboundCode,
        tableSuffix,
        shipmentId
      }
      const loadingInstance = Loading.service({ fullscreen: true })
      try {
        const { msg } = await addShipment(params)

        if (msg) {
          this.dialogVisible = true
          const obj = {
            platformCode,
            shopCode,
            siteCode,
            ...params
          }
          this._findOutboundAmazonDetail(obj)
        }
        loadingInstance.close()
        this.$notify({
          title: '操作成功',
          message: msg,
          type: 'success'
        })
        // 清除选中
        this.selectRadio = false
        this.shipmentPlanSelection.isUse = true
        this.shipmentPlanSelection = {}
      } catch (error) {
        console.log(error)
        loadingInstance.close()
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
